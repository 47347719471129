import axios from 'axios';
import { getItem, setItem, getEntity } from './storage.handler';

const apiUrl = process.env.REACT_APP_API_URL;
//#region Authentication

/** Authentication header */

const token = window.localStorage.getItem('token');
axios.defaults.headers = {};
const pathNames = ['login', 'tracker', 'quote', 'order-confirmation', 'schedule-public', ''];
const path = window.location.pathname.split('/')[1];

if (!token && path !== '/') {
    if (!pathNames.includes(path)) {
        window.location.replace('/');
    }
} else {
    axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
    };
}

export const syncDb = async (force = false) => {
    if (localStorage.getItem('syncTs')) {
        const lastSync = new Date(localStorage.getItem('syncTs'));
        const since = Math.abs(new Date() - lastSync) / 36e5;

        if (since > 1 || force) {
            await getClients(true);
            await getEvents(true);
            await getOrders(true);
            await getProducts(true);
            await getStations(true);
            await getQuotes(true);
            localStorage.setItem('syncTs', new Date());
        }
    }
};

//#endregion

//#region Clients

export const getClient = async (id) => {
    try {
        await getClients();
        return await getEntity('clients', id); // Assuming this function is already defined
    } catch (error) {
        console.error('Error in getClient:', error);
        window.alert('Error in getClient: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow if you want to propagate the error further
    }
};

export const getClients = async (force = false) => {
    try {
        if (!getItem('clients') || force) {
            const response = await axios.get(apiUrl + `clients/`);
            const clientsWithNoCompany = response.data
                .filter((c) => c.companyName === null)
                .sort((a, b) => a.fullName.localeCompare(b.fullName));
            const companies = response.data
                .filter((c) => c.companyName !== null)
                .sort((a, b) => a.companyName.localeCompare(b.companyName));
            setItem('clients', companies.concat(clientsWithNoCompany));
        }
        return getItem('clients');
    } catch (error) {
        console.error('Error in getClients:', error);
        window.alert('Error in getClients: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const filterActiveClients = async () => {
    try {
        await getClients(); // Assuming this populates the local storage
        let clientsFromStorage = getItem('clients'); // Retrieve the clients from local storage

        // Filter the clients to only include active ones
        let activeClients = clientsFromStorage.filter((client) => client.isActive);
        return activeClients;
    } catch (error) {
        console.error('Error in filterActiveClients:', error);
        window.alert('Error in filterActiveClients: ' + error.message); // Show alert with error message
        return []; // Return empty array in case of error
    }
};

export const updateClient = async (id, data) => {
    try {
        const response = await axios.put(apiUrl + `client/${id}/`, data);
        await getClients(true); // Force a refresh of the clients
        return response;
    } catch (error) {
        console.error('Error in updateClient:', error);
        window.alert('Error in updateClient: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const createClient = async (data) => {
    try {
        const response = await axios.post(apiUrl + `clients/create`, data);
        await getClients(true); // Force a refresh of the clients
        return response;
    } catch (error) {
        console.error('Error in createClient:', error);
        window.alert('Error in createClient: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const deleteClient = async (id) => {
    try {
        const response = await axios.delete(apiUrl + `client/delete/${id}/`);
        await getClients(true); // Force a refresh of the clients
        return response;
    } catch (error) {
        console.error('Error in deleteClient:', error);
        window.alert('Error in deleteClient: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

//#endregion

//#region Employees

export const getEmployee = async (id) => {
    try {
        await getEmployees();
        return await getEntity('employees', id); // Assuming this function is already defined
    } catch (error) {
        console.error('Error in getEmployee:', error);
        window.alert('Error in getEmployee: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const getEmployees = async (force = false) => {
    try {
        if (!getItem('employees') || force) {
            const response = await axios.get(apiUrl + `employees/`);
            setItem('employees', response.data);
        }
        return getItem('employees');
    } catch (error) {
        console.error('Error in getEmployees:', error);
        window.alert('Error in getEmployees: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const updateEmployee = async (id, data) => {
    try {
        const response = await axios.put(apiUrl + `employee/${id}/`, data);
        await getEmployees(true); // Force a refresh of the employees
        return response;
    } catch (error) {
        console.error('Error in updateEmployee:', error);
        window.alert('Error in updateEmployee: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const createEmployee = async (data) => {
    try {
        const response = await axios.post(apiUrl + `employees/create`, data);
        await getEmployees(true); // Force a refresh of the employees
        return response;
    } catch (error) {
        console.error('Error in createEmployee:', error);
        window.alert('Error in createEmployee: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const deleteEmployee = async (id) => {
    try {
        const response = await axios.delete(apiUrl + `employee/delete/${id}`);
        await getEmployees(true); // Force a refresh of the employees
        return response;
    } catch (error) {
        console.error('Error in deleteEmployee:', error);
        window.alert('Error in deleteEmployee: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

//#endregion

//#region Events

export const getEvent = async (id) => {
    try {
        await getEvents();
        return await getEntity('events', id); // Assuming this function is already defined
    } catch (error) {
        console.error('Error in getEvent:', error);
        window.alert('Error in getEvent: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const getEvents = async (force = false) => {
    try {
        if (!getItem('events') || force) {
            const response = await axios.get(apiUrl + `events/`);
            setItem('events', response.data);
        }
        return getItem('events');
    } catch (error) {
        console.error('Error in getEvents:', error);
        window.alert('Error in getEvents: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const updateEvent = async (id, data) => {
    try {
        const response = await axios.put(apiUrl + `event/${id}/`, data);
        await getEvents(true); // Force a refresh of the events
        return response;
    } catch (error) {
        console.error('Error in updateEvent:', error);
        window.alert('Error in updateEvent: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const createEvent = async (data) => {
    try {
        const response = await axios.post(apiUrl + `events/create`, data);
        await getEvents(true); // Force a refresh of the events
        return response;
    } catch (error) {
        console.error('Error in createEvent:', error);
        window.alert('Error in createEvent: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const deleteEvent = async (id) => {
    try {
        const response = await axios.delete(apiUrl + `event/delete/${id}`);
        await getEvents(true); // Force a refresh of the events
        return response;
    } catch (error) {
        console.error('Error in deleteEvent:', error);
        window.alert('Error in deleteEvent: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const deleteAllEvents = async () => {
    try {
        const response = await axios.delete(apiUrl + `event/delete-all/`);
        await getEvents(true); // Force a refresh of the events
        return response;
    } catch (error) {
        console.error('Error in deleteAllEvents:', error);
        window.alert('Error in deleteAllEvents: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const getEventsPublic = async () => {
    try {
        return await axios.get(apiUrl + `events-public/`);
    } catch (error) {
        console.error('Error in getEvents:', error);
        window.alert('Error in getEvents: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

//#endregion

//#region Orders

export const getOrder = async (id) => {
    try {
        await getOrders();
        return await getEntity('orders', id); // Assuming this function is already defined
    } catch (error) {
        console.error('Error in getOrder:', error);
        window.alert('Error in getOrder: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const getOrders = async (force = false) => {
    try {
        if (!getItem('orders') || force) {
            const response = await axios.get(apiUrl + `orders/`);
            setItem('orders', response.data);
        }
        return getItem('orders');
    } catch (error) {
        console.error('Error in getOrders:', error);
        window.alert('Error in getOrders: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const updateOrder = async (id, data) => {
    try {
        const response = await axios.put(apiUrl + `order/${id}/`, data);
        await getOrders(true); // Force a refresh of the orders
        return response;
    } catch (error) {
        console.error('Error in updateOrder:', error);
        window.alert('Error in updateOrder: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const createOrder = async (data) => {
    try {
        const response = await axios.post(apiUrl + `orders/create`, data);
        await getOrders(true); // Force a refresh of the orders
        return response;
    } catch (error) {
        console.error('Error in createOrder:', error);
        window.alert('Error in createOrder: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const deleteOrder = async (id) => {
    try {
        const response = await axios.delete(apiUrl + `order/delete/${id}`);
        await getOrders(true); // Force a refresh of the orders
        await getEvents(true); // Force a refresh of the events
        return response;
    } catch (error) {
        console.error('Error in deleteOrder:', error);
        window.alert('Error in deleteOrder: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const trackOrder = async (secret) => {
    try {
        const response = await axios.get(apiUrl + `track/${secret}`);
        if (response.status === 200) {
            setItem('tracker', response.data);
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            setItem('tracker', error.response.data);
        } else {
            console.error('Error in trackOrder:', error);
            window.alert('Error in trackOrder: ' + error.message); // Show alert with error message
            throw error; // Optionally rethrow
        }
    }

    return getItem('tracker');
};

export const trackPublicOrder = async (secret) => {
    try {
        const response = await axios.get(apiUrl + `track-public/${secret}`);
        if (response.status === 200) {
            setItem('tracker', response.data);
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            setItem('tracker', error.response.data);
        } else {
            console.error('Error in trackOrder:', error);
            window.alert('Error in trackOrder: ' + error.message); // Show alert with error message
            throw error; // Optionally rethrow
        }
    }

    return getItem('tracker');
};

//#endregion

//#region Products

export const getProduct = async (id) => {
    try {
        await getProducts();
        return await getEntity('products', id); // Assuming this function is already defined
    } catch (error) {
        console.error('Error in getProduct:', error);
        window.alert('Error in getProduct: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const getProducts = async (force = false) => {
    try {
        if (!getItem('products') || force) {
            const response = await axios.get(apiUrl + `products/`);
            setItem('products', response.data);
        }
        return getItem('products');
    } catch (error) {
        console.error('Error in getProducts:', error);
        window.alert('Error in getProducts: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const updateProduct = async (id, data) => {
    try {
        const response = await axios.put(apiUrl + `product/${id}/`, data);
        await getProducts(true); // Force a refresh of the products
        return response;
    } catch (error) {
        console.error('Error in updateProduct:', error);
        window.alert('Error in updateProduct: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const createProduct = async (data) => {
    try {
        const response = await axios.post(apiUrl + `products/create`, data);
        await getProducts(true); // Force a refresh of the products
        return response;
    } catch (error) {
        console.error('Error in createProduct:', error);
        window.alert('Error in createProduct: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const deleteProduct = async (id) => {
    try {
        const response = await axios.delete(apiUrl + `product/delete/${id}`);
        await getProducts(true); // Force a refresh of the products
        return response;
    } catch (error) {
        console.error('Error in deleteProduct:', error);
        window.alert('Error in deleteProduct: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

//#endregion

//#region Stations

export const getStation = async (id) => {
    try {
        await getStations();
        return await getEntity('stations', id); // Assuming this function is already defined
    } catch (error) {
        console.error('Error in getStation:', error);
        window.alert('Error in getStation: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const getStations = async (force = false) => {
    try {
        if (!getItem('stations') || force) {
            const response = await axios.get(apiUrl + `stations/`);
            setItem('stations', response.data);
        }
        return getItem('stations');
    } catch (error) {
        console.error('Error in getStations:', error);
        window.alert('Error in getStations: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const updateStation = async (id, data) => {
    try {
        const response = await axios.put(apiUrl + `station/${id}/`, data);
        await getStations(true); // Force a refresh of the stations
        return response;
    } catch (error) {
        console.error('Error in updateStation:', error);
        window.alert('Error in updateStation: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const createStation = async (data) => {
    try {
        const response = await axios.post(apiUrl + `stations/create`, data);
        await getStations(true); // Force a refresh of the stations
        return response;
    } catch (error) {
        console.error('Error in createStation:', error);
        window.alert('Error in createStation: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const deleteStation = async (id) => {
    try {
        const response = await axios.delete(apiUrl + `station/delete/${id}`);
        await getStations(true); // Force a refresh of the stations
        return response;
    } catch (error) {
        console.error('Error in deleteStation:', error);
        window.alert('Error in deleteStation: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

//#endregion

//#region Quotes

export const getQuote = async (id, hasRetried = false) => {
    try {
        await getQuotes(hasRetried);
        const result = getEntity('quotes', id);

        if (result === undefined && !hasRetried) {
            return await getQuote(id, true); // Retry the request if not found
        }

        return result;
    } catch (error) {
        console.error('Error in getQuote:', error);
        window.alert('Error in getQuote: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const getQuotes = async (force = false) => {
    try {
        if (!getItem('quotes') || force) {
            const response = await axios.get(apiUrl + `quotes/`);
            setItem('quotes', response.data);
        }
        return getItem('quotes');
    } catch (error) {
        console.error('Error in getQuotes:', error);
        window.alert('Error in getQuotes: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const updateQuote = async (id, data) => {
    try {
        const response = await axios.put(apiUrl + `quote/${id}/`, data);
        await getQuotes(true); // Force a refresh of the quotes
        return response;
    } catch (error) {
        console.error('Error in updateQuote:', error);
        window.alert('Error in updateQuote: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const createQuote = async (data) => {
    try {
        const response = await axios.post(apiUrl + `quotes/create`, data);
        await getQuotes(true); // Force a refresh of the quotes
        return response;
    } catch (error) {
        console.error('Error in createQuote:', error);
        window.alert('Error in createQuote: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const deleteQuote = async (id) => {
    try {
        const response = await axios.delete(apiUrl + `quote/delete/${id}`);
        await getQuotes(true); // Force a refresh of the quotes
        return response;
    } catch (error) {
        console.error('Error in deleteQuote:', error);
        window.alert('Error in deleteQuote: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const sendQuote = async (id, data) => {
    try {
        const response = await axios.put(apiUrl + `send-quote/${id}/`, data);
        await getQuotes(true); // Force a refresh of the quotes
        return response;
    } catch (error) {
        console.error('Error in sendQuote:', error);
        window.alert('Error in sendQuote: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const requestQuote = async (data) => {
    try {
        return await axios.post(apiUrl + `request-quote/`, data);
    } catch (error) {
        console.error('Error in requestQuote:', error);
        window.alert('Error in requestQuote: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

export const quoteResponse = async (id, r) => {
    try {
        return await axios.get(apiUrl + `quote-response/${id}/${r}/`);
    } catch (error) {
        console.error('Error in quoteResponse:', error);
        window.alert('Error in quoteResponse: ' + error.message); // Show alert with error message
        throw error; // Optionally rethrow
    }
};

//#endregion
