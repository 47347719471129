import moment from 'moment';
import { useEffect, useState } from 'react';
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler';
import { Helmet } from 'react-helmet-async';
import { getEventsPublic, getStation } from '../../../Services/api.service';
import { unixTimestampToDateTime } from '../../../Components/DatePicker/DatePicker';
import styles from './schedule.module.scss';
import Loader from '../../../Components/Loader/Loader';
import withDndContext from '../../../Components/Scheduler/withDndContext';

const SchedulePublic = () => {
    const [loading, setLoading] = useState(true);
    const [resources, setResources] = useState([]);
    const [events, setEvents] = useState([]);
    const [schedulerData, setSchedulerData] = useState(
        new SchedulerData(moment().format(DATE_FORMAT), ViewTypes.Week, false, false, {
            displayWeekend: false,
            weekCellWidth: '16%',
            schedulerWidth: '95%',
            resourceName: 'İstasyonlar ' + getWeekStartAndEnd(new Date()),
            dayStartFrom: 8,
            dayStopTo: 16,
            views: [
                {
                    viewName: 'Gün',
                    viewType: ViewTypes.Day,
                    showAgenda: false,
                    isEventPerspective: false,
                },
                {
                    viewName: 'Hafta',
                    viewType: ViewTypes.Week,
                    showAgenda: false,
                    isEventPerspective: false,
                },
            ],
        }),
    );

    useEffect(() => {
        const fetchEventsAndResources = async () => {
            try {
                setLoading(true); // Start loading
                
                let currentEvents;
                await getEventsPublic().then(response => {
                    currentEvents = response.data;
                });
    
                // Format the events
                const formattedEvents = await Promise.all(
                    currentEvents['events'].map(async (event) => ({
                        ...event,
                        bgColor: '#c3c3c3',
                        resourceId: await getSlotIdBasedOnResourceId(event.resourceId),
                        start: unixTimestampToDateTime(event.start),
                        end: unixTimestampToDateTime(event.end),
                    }))
                );
    
                setEvents(formattedEvents);
    
                // Format the resources
                const formattedResources = currentEvents['stations'].map((resource) => ({
                    id: resource.stationNo,
                    name: `${resource.title} (${resource.type})`,
                }));
    
                setResources(formattedResources);
            } catch (error) {
                console.error('Error fetching events or resources:', error);
                window.alert('Failed to fetch events or resources. Please try again later.');
            } finally {
                setLoading(false); // Stop loading
            }
        };
    
        fetchEventsAndResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);    

    useEffect(() => {
        if (resources.length > 0) {
            const newSchedulerData = Object.assign(
                Object.create(Object.getPrototypeOf(schedulerData)),
                schedulerData,
            );

            newSchedulerData.setResources(resources);
            newSchedulerData.setEvents(events);
            setSchedulerData(newSchedulerData);
        }
        // eslint-disable-next-line
    }, [resources, events]);

    function getWeekStartAndEnd(dateInput) {
        const date = new Date(dateInput);
        
        // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const currentDay = date.getDay();
        
        // Calculate the start and end dates of the week
        const startDate = new Date(date);
        startDate.setDate(date.getDate() - currentDay + 1); // Start of the week (Sunday)
        
        const endDate = new Date(date);
        endDate.setDate(date.getDate() + (5 - currentDay)); // End of the week (Saturday)
        
        // Get month name
        const monthName = startDate.toLocaleString('default', { month: 'long' });
        
        // Get day numbers
        const weekStartDay = startDate.getDate();
        const weekEndDay = endDate.getDate();
        
        return `${monthName}, ${weekStartDay} - ${weekEndDay}`;
    }

    const getSlotIdBasedOnResourceId = async (resourceId) => {
        const station = await getStation(resourceId);
        return station.stationNo;
    };

    const doNothing = () => {
        return;
    };

    return (
        <>
            <Helmet>
                <title>Selnikel - Schedule</title>
                <meta name='description' content='Schedule of the week' />
                <link rel='canonical' href='/schedule-public' />
            </Helmet>
            {loading ? (
                <Loader />
            ): (
                <div className={styles.container}>
                    <Scheduler
                        schedulerData={schedulerData}
                        onViewChange={doNothing}
                        onSelectDate={doNothing}
                        prevClick={doNothing}
                        nextClick={doNothing}
                    />
                </div>
            )}
        </>
    );
};

export default withDndContext(SchedulePublic);
;
