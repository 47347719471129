import styles from './dropdown.module.scss';
import {
    getEmployees,
    getClients,
    filterActiveClients,
    getProducts,
    getOrders,
} from '../../Services/api.service';
import { useEffect, useState } from 'react';

const Dropdown = (props) => {
    const [dropdownItems, setDropdownItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [displayDropDownItems, setDisplayDropDownItems] = useState(false);

    useEffect(() => {
        (async () => {
            const result = await functionMap[props.options]();
            setDropdownItems(result || []);
            setFilteredItems(result || []);
            
            if (props.value !== -1) {
                const item = result.find(i => i.id === props.value);
                const defaultText = item[props.displayKey];
                document.getElementById(`searchValue-${props.label}`).value = defaultText;
            };
        })();
    }, [props]);

    let dataRender;
    dataRender = filteredItems.map((i) => (
        <div key={i[props.displayKey]} className={styles.dropdownItem} onClick={() => handleSelectClick(i)}>
            {i[props.displayKey]}
        </div>
    ));

    const handleSearchClick = (from) => {
        if (displayDropDownItems && from === 'input') {
            return; // Early return to prevent further execution
        }
    
        setDisplayDropDownItems(prev => !prev); // Update state after return is skipped
    };

    const handleSearch = (kwargs) => {
        const copyList = dropdownItems.filter((item) =>{
            if (props.filterItems?.length) {
                // If props.filterItems is provided, check multiple keys
                return props.filterItems.some((key) => 
                    item[key]?.toString().toLowerCase().includes(kwargs.toLowerCase())
                );
            } else {
                // If filterItems is not provided, use displayKey
                return item[props.displayKey]?.toString().toLowerCase().includes(kwargs.toLowerCase());
            }
        });

        setFilteredItems(copyList);
    }

    const handleSelectClick = (item) => {
        document.getElementById(`searchValue-${props.label}`).value = item[props.displayKey];
        handleSearchClick();
        if (props.onChange) {
            props.onChange({ target: { value: item.id } });
        }
    }

    return (
        <>
            <div className={styles.dropdownContainer}>
                <p><b>{props.label}</b></p>
                
                <input
                    id={`searchValue-${props.label}`}
                    placeholder='Sec'
                    onChange={(e) => handleSearch(e.target.value)}
                    onClick={() => handleSearchClick('input')}
                    disabled={props.disabled}
                />

                {displayDropDownItems ?
                <>
                    <div className={styles.backDrop} onClick={() => handleSearchClick()} />
                    {filteredItems.length > 0 ?
                        <div className={styles.dropdownItemContainer}>
                            {dataRender}
                        </div>
                    :
                        null
                    }
                </>
                :
                    null
                }
            </div>
        </>
    );
};

export default Dropdown;

const functionMap = {
    getEmployees: getEmployees,
    getClients: getClients,
    filterActiveClients: filterActiveClients,
    getProducts: getProducts,
    getOrders: getOrders,
};