import styles from './button.module.scss';

const Button = (props) => {
    return (
        <button
            className={`${styles.button} ${props.disabled ? styles.disabled : ''}`}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.loading ? (
                    <div className={styles.buttonLoader}></div>
                ) : (
                    props.submitted ? (
                        <div className={styles.responseMessage}>{props.responseMessage}</div>
                    ) : (
                        props.buttonText
                    )
                )
            }
        </button>
    );
};

export default Button;
