import styles from './eventpopup.module.scss';
import InputField from '../InputField/InputField';
import Button from '../Button/Button';
import close from '../../Assets/Images/Icons/Other/cross-red.png'
import DatePicker, { dateToUnixTimeStamp } from '../DatePicker/DatePicker';
import { useEffect, useState } from 'react';

const EventPopUp = ({ currentEvent, handleBackdrop }) => {
    const [newDate, setNewDate] = useState('');

    useEffect(() => {
        setNewDate(dateToUnixTimeStamp(currentEvent.start));
    }, [currentEvent.start])

    if (!newDate) {
        return;
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src = {close} alt = "close" onClick={handleBackdrop} />
            </div>
            <InputField
                disabled = {true}
                label = "Sipariş"
                value = {currentEvent.title}
            />
            <InputField
                disabled = {true}
                label = "İstasyon"
                value = {currentEvent.resourceTitle}
            />
            <InputField
                disabled = {true}
                label = "Saat"
                value = {currentEvent.hours}
            />
            <DatePicker
                label = "Başlama Tarihi"
                initialTimestamp = {newDate}
                onChange={(unixTimeStamp) => {
                    setNewDate(unixTimeStamp)
                }}
            />
            <DatePicker
                disabled = {true}
                label = "Bitiş Tarihi"
                initialTimestamp = {dateToUnixTimeStamp(currentEvent.end)}
            />
            <div className={styles.centerButton}>
                <Button
                    buttonText = "Güncelle"
                    onClick={handleBackdrop}
                />
            </div>
        </div>
    );
};

export default EventPopUp;
