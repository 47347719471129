import React, { useEffect, useState } from 'react';
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT } from 'react-big-scheduler';
import moment from 'moment';
import withDndContext from './withDndContext';
import 'react-big-scheduler/lib/css/style.css';
import { createEvent, getEvents, getStation, getOrder, getProduct } from '../../Services/api.service';
import Loader from '../Loader/Loader';
import { dateToUnixTimeStamp, unixTimestampToDateTime } from '../DatePicker/DatePicker';
import Button from '../Button/Button';
import styles from './schedulercomp.module.scss';
import './schedulercomponent.css';
import EventPopUpModal from '../EventPopUpModal/EventPopUpModal';
import TextArea from '../TextArea/TextArea';
import Dropdown from '../Dropdown/Dropdown';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const COLOR_LIST = ['#B4B4B4', '#FF8931'];

const SchedulerComponent = () => {
    const navigate = useNavigate();
    const [order, setOrder] = useState();
    const [orderObj, setOrderObj] = useState();
    const [product, setProduct] = useState();
    const [resultText, setResultText] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [scheduledDeliveryDate, setScheduledDeliveryDate] = useState('');

    const [refreshEvents, setRefreshEvents] = useState(false);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deliveryDateLoader, setDeliveryDateLoader] = useState(false);
    const [resources, setResources] = useState([]);
    const [groupedEvents, setGroupedEvents] = useState([]);
    const [displayPopUp, setDisplayPopUp] = useState(false);
    const [currentEvent, setCurrentEvent] = useState({});
    const [eventsJsonData, setEventsJsonData] = useState({});

    const [schedulerData, setSchedulerData] = useState(
        new SchedulerData(moment().format(DATE_FORMAT), ViewTypes.Week, false, false, {
            displayWeekend: false,
            weekCellWidth: '16%',
            schedulerWidth: '95%',
            resourceName: 'İstasyonlar',
            dayStartFrom: 8,
            dayStopTo: 16,
            views: [
                {
                    viewName: 'Gün',
                    viewType: ViewTypes.Day,
                    showAgenda: false,
                    isEventPerspective: false,
                },
                {
                    viewName: 'Hafta',
                    viewType: ViewTypes.Week,
                    showAgenda: false,
                    isEventPerspective: false,
                },
            ],
        }),
    );

    useEffect(() => {
        const getColorForOrderId = (eventOrderId) => {
            if (orderObj && orderObj.id === eventOrderId) {
                return COLOR_LIST[1];
            }
            else {
                return COLOR_LIST[0];
            }
        }
        (async () => {
            try {
                const currentEvents = await getEvents(true);
    
                // Format the events
                const formattedEvents = await Promise.all(
                    currentEvents['events'].map(async (event) => ({
                        ...event,
                        bgColor: getColorForOrderId(event.orderId),
                        resourceId: await getSlotIdBasedOnResourceId(event.resourceId),
                        start: unixTimestampToDateTime(event.start, -3),
                        end: unixTimestampToDateTime(event.end, -3),
                    })),
                );
    
                // Set the events
                setEvents(formattedEvents);
                setGroupedEvents(groupAndSortEvents(formattedEvents));
    
                // Filter resources based on allowedResources prop
                let fetchedResources = currentEvents['stations'];
                let formattedResources = fetchedResources.map((resource) => ({
                    id: resource.stationNo,
                    name: `${resource.title} (${resource.type})`,
                }));
    
                setResources(formattedResources);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            } catch (error) {
                navigate('/404');
            }
        })();
        // eslint-disable-next-line
    }, [orderObj, refreshEvents]); // Listen to refreshEvents

    useEffect(() => {
        if (resources.length > 0) {
            const newSchedulerData = Object.assign(
                Object.create(Object.getPrototypeOf(schedulerData)),
                schedulerData,
            );

            newSchedulerData.setResources(resources);
            newSchedulerData.setEvents(events);
            setSchedulerData(newSchedulerData);
        }
        // eslint-disable-next-line
    }, [resources, events]);

    useEffect(() => {
        if (orderObj && product && resources.length > 0) {
            setEventsJsonData(prepareEventJson());
        }
    // eslint-disable-next-line
    }, [orderObj, product, resources]);

    useEffect(() => {
        if (JSON.stringify(eventsJsonData) !== '{}') {
            setDeliveryDateLoader(true);
            const result = eventsJsonData?.events?.find(item => item.hasOwnProperty('montaj'));
            const possibleDeliveryDate = getNextDay8AM(result?.montaj?.endDate);
            if (possibleDeliveryDate) {
                const formattedEndDate = new Date(possibleDeliveryDate);
                setDeliveryDate(formattedEndDate.toLocaleDateString('tr-TR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }));
            }
            setTimeout(() => {
                setDeliveryDateLoader(false);
            }, 1000);
        }
    // eslint-disable-next-line
    }, [eventsJsonData]);

    const prevClick = () => {
        schedulerData.prev();
        setSchedulerData(
            Object.assign(Object.create(Object.getPrototypeOf(schedulerData)), schedulerData),
        );
        setLoading(true);
        setRefreshEvents((prev) => !prev);
    };

    const nextClick = () => {
        schedulerData.next();
        setSchedulerData(
            Object.assign(Object.create(Object.getPrototypeOf(schedulerData)), schedulerData),
        );
        setLoading(true);
        setRefreshEvents((prev) => !prev);
    };

    const onViewChange = (_, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        setSchedulerData(
            Object.assign(Object.create(Object.getPrototypeOf(schedulerData)), schedulerData),
        );
        setRefreshEvents((prev) => !prev);
    };

    const onSelectDate = (_, date) => {
        const newSchedulerData = new SchedulerData(
            moment(date).format(DATE_FORMAT),
            schedulerData.viewType,
            schedulerData.events,
            schedulerData.resources,
        );
        setSchedulerData(newSchedulerData); // Update the scheduler data to reflect the selected date
    };

    const getSlotIdBasedOnResourceId = async (resourceId) => {
        const station = await getStation(resourceId);
        return station.stationNo;
    };

    const groupAndSortEvents = (rawItems) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to the start of the current day

        const grouped = rawItems
            .filter((item) => {
                const startDate = new Date(item.start);
                const endDate = new Date(item.end);

                // Include events that start today or in the future, or have already started but end in the future
                return startDate >= today || endDate >= today;
            })
            .reduce((acc, item) => {
                const id = item.resourceId;

                if (!acc[id]) {
                    acc[id] = [];
                }

                acc[id].push(item);

                return acc;
            }, {});

        // Sort items in each group from oldest to newest
        Object.keys(grouped).forEach((key) => {
            grouped[key].sort((a, b) => new Date(a.start) - new Date(b.start));
        });

        // Convert the grouped object into an array of dictionaries
        const result = Object.keys(grouped).map((key) => ({
            resourceId: key,
            items: grouped[key],
        }));

        return result;
    };

    const eventClicked = (_, event) => {
        setCurrentEvent(event);
        handleBackdrop();
    };

    const handleBackdrop = () => {
        setDisplayPopUp(!displayPopUp);
        const body = document.body;
        if (displayPopUp) {
            body.style.overflow = 'auto';
        } else {
            body.style.overflow = 'hidden';
        }
    };

    const createEvents = async () => {
        setLoading(true);
        try {
            setScheduledDeliveryDate(deliveryDate);
            await createEvent(eventsJsonData);
            setRefreshEvents((prev) => !prev); // Toggle the refreshEvents flag
        } catch (error) {
            console.error('Error creating events:', error);
            // Optionally, handle the error (show a message, etc.)
        }
    }

    const resetKaynakEvents = (listOfEvents) => {
        listOfEvents = [];
        const kaynakStations = ['7','8','9','10'];

        groupedEvents.forEach(element => {
            if (kaynakStations.includes(element.resourceId)) {
                listOfEvents.push(element);
            }
        });
        return listOfEvents;
    }

    const prepareEventJson = () => {
        const hoursOfStationsForProduct = extractHoursForStations(product);

        let eventsJson = {};
        eventsJson['orderId'] = orderObj.id;
        eventsJson['events'] = [];
        eventsJson['latestEndDate'] = '';

        let holdKaynakEvents = resetKaynakEvents();
        
        hoursOfStationsForProduct.forEach(element => {
            if (!element.key.includes('Kaynak')) {
                if (element.key === 'balans') {
                    const rotorStationText = 'rotorKaynak';
                    const rotorKaynak = eventsJson['events'].find(obj => Object.keys(obj)[0] === rotorStationText);
                    const rotorKaynakEndDate = (rotorKaynak[rotorStationText].endDate);

                    const result = findAvailableSlotForResourceReturnStartDate(element.stationNumbers, element.key, element.hour, null, rotorKaynakEndDate);
                    eventsJson['latestEndDate'] = getLatestDate(eventsJson['latestEndDate'], result.endDate);
                    eventsJson['events'].push({
                        [element.key]: {...result, hour: element.hour},
                    });
                }
                else if (element.key === 'montaj') {
                    const result = findAvailableSlotForResourceReturnStartDate(element.stationNumbers, element.key, element.hour, null, eventsJson['latestEndDate']);
                    eventsJson['latestEndDate'] = getLatestDate(eventsJson['latestEndDate'], result.endDate);
                    eventsJson['events'].push({
                        [element.key]: {...result, hour: element.hour},
                    });
                }
                else {

                    const result = findAvailableSlotForResourceReturnStartDate(element.stationNumbers, element.key, element.hour);
                    eventsJson['latestEndDate'] = getLatestDate(eventsJson['latestEndDate'], result.endDate);
                    eventsJson['events'].push({
                        [element.key]: {...result, hour: element.hour},
                    });
                }
            }
            else {
                const currentKey = element.key.replace("Kaynak", "");
                const currentStationForKaynak = eventsJson['events'].find(obj => Object.keys(obj)[0] === currentKey);
                const currentStationEndDate = (currentStationForKaynak[currentKey].endDate);
                const resultEvent = {...findAvailableSlotForResourceReturnStartDate(element.stationNumbers, 'Kaynak', element.hour, holdKaynakEvents, currentStationEndDate), hour: element.hour};
                eventsJson['latestEndDate'] = getLatestDate(eventsJson['latestEndDate'], resultEvent.endDate);
                holdKaynakEvents = modifyKaynakList(holdKaynakEvents, resultEvent);
                eventsJson['events'].push({
                    [element.key]: resultEvent,
                });
            }
        });

        holdKaynakEvents = resetKaynakEvents();
        return eventsJson;
    }

    const modifyKaynakList = (listOfEvents, resultEvent) => {
        const stationNoString = resultEvent.stationNo.toString();
    
        // Find the event resource group in the list
        let eventResourceGroup = listOfEvents.find(e => e.resourceId === stationNoString);
    
        const itemObj = {
            start: resultEvent.startDate,
            end: resultEvent.endDate,
            availableHours: 0 // Placeholder; will be updated later
        };
    
        if (eventResourceGroup) {
            // Insert the item into the correct position based on `start`
            const items = eventResourceGroup.items;
            let inserted = false;
            const newStartTs = dateToUnixTimeStamp(new Date(resultEvent.startDate));
    
            for (let i = 0; i < items.length; i++) {
                const existingEvent = items[i];
                const existingStartTs = dateToUnixTimeStamp(new Date(existingEvent.start));
    
                if (newStartTs < existingStartTs) {
                    items.splice(i, 0, itemObj);
                    inserted = true;
                    break;
                }
            }
    
            if (!inserted) {
                items.push(itemObj); // Add to the end if no earlier position fits
            }
        } else {
            // Create a new group if it doesn't exist
            eventResourceGroup = {
                resourceId: stationNoString,
                items: [itemObj]
            };
            listOfEvents.push(eventResourceGroup);
        }
    
        // Update availableHours within the event resource group
        const items = eventResourceGroup.items;
        for (let idx = 0; idx < items.length; idx++) {
            const event = items[idx];
    
            if (idx > 0) { // Update availableHours of the previous event
                const previousEvent = items[idx - 1];
                const previousEventEndTs = dateToUnixTimeStamp(new Date(previousEvent.end));
                const currentEventStartTs = dateToUnixTimeStamp(new Date(event.start));
    
                previousEvent.availableHours = workHourCalculatorBetweenTwoEvents(previousEventEndTs, currentEventStartTs) - 9;
            }
    
            if (idx === items.length - 1) { // Update availableHours of the last event
                event.availableHours = 999; // No next event, so set to a default value
            }
        }
    
        return listOfEvents;
    };

    // Placeholder for the work hour calculation function
    const workHourCalculatorBetweenTwoEvents = (laterTime, earlierTime) => {
        try {
            const endDate = new Date(laterTime * 1000);
            const startDate = new Date(earlierTime * 1000);
    
            let weekdayCount = 0;
            while (endDate < startDate) {
                if (endDate.getDay() < 5) { // getDay() returns 0 for Sunday and 6 for Saturday
                    weekdayCount += 1;
                }
                endDate.setDate(endDate.getDate() + 1);
            }
    
            if (weekdayCount === 0) {
                return 0;
            }
    
            const availableHours = (weekdayCount - 1) * 9;
            return availableHours;
        } catch (error) {
            throw new Error(`Error calculating work hours: ${error.message}`);
        }
    };
    
    const findAvailableSlotForResourceReturnStartDate = (stations, key, hour, kaynakEventsList = null, startAfter = null) => {
        let possibleStartDatesFromAllStations = [];

        if (key !== 'Kaynak') {
            stations.forEach(stationNo => {
                possibleStartDatesFromAllStations.push({
                    'stationNo': stationNo,
                    'startDate': returnEarliestDateForSingleStation(stationNo, hour, startAfter),
                })
            });
        }
        else {
            stations.forEach(stationNo => {
                possibleStartDatesFromAllStations.push({
                    'stationNo': stationNo,
                    'startDate': returnEarliestDateForSingleKaynakStation(stationNo, hour, kaynakEventsList, startAfter),
                })
            });
        }

        const correctedStartDate = returnEarliestDateFromAllPossibleStations(possibleStartDatesFromAllStations);
        const endDate = calculateEndDate(correctedStartDate.startDate, hour);

        return {...correctedStartDate, endDate: formatDate(endDate)};
    }

    const returnEarliestDateFromAllPossibleStations = (listOfDates) => {
        const sortedDates = listOfDates.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        return sortedDates[0];
    };

    const returnEarliestDateForSingleStation = (stationNo, hour, startAfter = '') => {
        let startDate = '';
    
        // Convert startAfter to a Date object if provided
        const startAfterDate = startAfter ? new Date(startAfter) : null;
    
        // Filter station events based on stationNo and startAfterDate
        const stationEvents = groupedEvents
            .find(s => parseInt(s.resourceId) === stationNo)?.items || [];
    
        const filteredEvents = stationEvents
            .filter(event => !startAfterDate || new Date(event.end) > startAfterDate)
            .sort((a, b) => new Date(a.start) - new Date(b.start));
    
        if (filteredEvents.length > 0) {
            const firstEvent = filteredEvents[0];
            const startDateOfFirstEvent = dateToUnixTimeStamp(new Date(firstEvent.start));
            let nextDay8AM = startAfter ? startAfter : getNextDay8AM();
            const nextDayTs = dateToUnixTimeStamp(new Date(nextDay8AM));
    
            // Check if there are available hours before the first event
            const availableHoursBeforeFirstEvent =
                workHourCalculatorBetweenTwoEvents(nextDayTs, startDateOfFirstEvent) - 9;
    
            if (availableHoursBeforeFirstEvent < hour) {
                for (const event of filteredEvents) {
                    if (event.availableHours > hour) {
                        startDate = ensureNextDay8AM(event.end);
                        break;
                    }
                }
            } else {
                // If the time is schedulable before the first event
                startDate = startAfterDate
                    ? getNextDay8AM(startAfterDate)
                    : getNextDay8AM();

                const startDateObj = new Date(startDate);
                const firstEventEndDate = new Date(firstEvent.end);
    
                if (startDateObj === firstEventEndDate) {
                    startDate = ensureNextDay8AM(startDateObj);
                }
            }
        } else {
            // If no events, set startDate to next day 8 AM
            startDate = startAfterDate
                ? getNextDay8AM(startAfterDate)
                : getNextDay8AM();
        }
    
        return startDate;
    };

    const returnEarliestDateForSingleKaynakStation = (stationNo, hour, kaynakEventsList, startAfter = '') => {
        let startDate = '';
    
        // Convert startAfter to a Date object if provided
        const startAfterDate = startAfter ? new Date(startAfter) : null;
    
        // Filter events for the specified station and occurring after startAfterDate
        const stationEvents = kaynakEventsList
            .find(s => parseInt(s.resourceId) === stationNo)?.items || [];
    
        const filteredEvents = stationEvents
            .filter(event => !startAfterDate || new Date(event.end) > startAfterDate)
            .sort((a, b) => new Date(a.start) - new Date(b.start));
    
        if (filteredEvents.length > 0) {
            const firstEvent = filteredEvents[0];
            const startDateOfFirstEvent = dateToUnixTimeStamp((new Date(firstEvent.start)));
            let nextDay8AM = startAfter ? startAfter : getNextDay8AM();
            const nextDayTs = dateToUnixTimeStamp(new Date(nextDay8AM));
    
            // Check if there are available hours before the first event
            const availableHoursBeforeFirstEvent =
                workHourCalculatorBetweenTwoEvents(nextDayTs, startDateOfFirstEvent) - 9;
    
            if (availableHoursBeforeFirstEvent < hour) {
                for (const event of filteredEvents) {
                    if (event.availableHours > hour) {
                        startDate = ensureNextDay8AM(event.end);
                        break;
                    }
                }
            } else {
                // If the time is schedulable before the first event
                startDate = startAfterDate
                    ? getNextDay8AM(startAfterDate)
                    : getNextDay8AM();
    
                const startDateObj = new Date(startDate);
                const firstEventEndDate = new Date(firstEvent.end);
    
                if (startDateObj === firstEventEndDate) {
                    startDate = ensureNextDay8AM(startDateObj);
                }
            }
        } else {
            // If no events, set startDate to next day 8 AM
            startDate = startAfterDate
                ? getNextDay8AM(startAfterDate)
                : getNextDay8AM();
        }
    
        return startDate;
    };

    const calculateEndDate = (startDate, remainingHours) => {
        const endDate = new Date(startDate);
        while (remainingHours > 0) {
            const day = endDate.getDay();
            if (day >= 1 && day <= 5) {
                const hoursToDeduct = Math.min(remainingHours, 9);
                remainingHours -= hoursToDeduct;

                endDate.setHours(endDate.getHours() + hoursToDeduct);

                if (remainingHours > 0) {
                    endDate.setDate(endDate.getDate() + 1);
                    endDate.setHours(8, 0, 0, 0);
                }
            } else {
                endDate.setDate(endDate.getDate() + 1);
                endDate.setHours(8, 0, 0, 0);
            }
        }
        return endDate;
    };

    const getNextDay8AM = (dateStr = '') => {
        let baseDate = dateStr ? new Date(dateStr) : new Date();
    
        // Create a new Date object for the next day
        let nextDay = new Date(baseDate);
        nextDay.setDate(baseDate.getDate() + 1);
    
        // Ensure the day is not a weekend
        while (nextDay.getDay() === 6 || nextDay.getDay() === 0) { // 6 = Saturday, 0 = Sunday
            nextDay.setDate(nextDay.getDate() + 1);
        }
    
        // Set the time to 8:00 AM
        nextDay.setHours(8, 0, 0, 0); // Hours, Minutes, Seconds, Milliseconds
    
        return formatDate(nextDay);
    };

    const ensureNextDay8AM = (inputDateTime) => {
        try {
            // Parse the input string to a Date object
            if (!inputDateTime || typeof inputDateTime !== "string") {
                throw new Error("Invalid input: Must be a non-empty string.");
            }
    
            // Replace space with "T" for ISO compatibility
            let inputDate = new Date(inputDateTime.replace(" ", "T"));
    
            // Check if the date is valid
            if (isNaN(inputDate.getTime())) {
                throw new Error("Invalid date format. Expected 'YYYY-MM-DD HH:MM'.");
            }
    
            // Move to the next day and set the time to 8:00 AM
            inputDate.setDate(inputDate.getDate() + 1);
    
            // Ensure the day is not a weekend
            while (inputDate.getDay() === 6 || inputDate.getDay() === 0) { // 6 = Saturday, 0 = Sunday
                inputDate.setDate(inputDate.getDate() + 1);
            }
    
            // Set the time to 8:00 AM
            inputDate.setHours(8, 0, 0, 0);
    
            // Format the result as YYYY-MM-DD HH:MM
            const year = inputDate.getFullYear();
            const month = String(inputDate.getMonth() + 1).padStart(2, '0');
            const day = String(inputDate.getDate()).padStart(2, '0');
            const hours = String(inputDate.getHours()).padStart(2, '0');
            const minutes = String(inputDate.getMinutes()).padStart(2, '0');
    
            return `${year}-${month}-${day} ${hours}:${minutes}`;
        } catch (error) {
            console.error("Error:", error.message);
            return null;
        }
    };

    const formatDate = (date) => {
        // Get components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        // Format as YYYY-MM-DD HH:MM
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    const extractHoursForStations = (product) => {
        let outputDict = {};
        const ignoredKeys = ['id', 'createTs', 'title', 'type']; // Keys to ignore
    
        for (let key in product) {
            if (product.hasOwnProperty(key) && !ignoredKeys.includes(key) && parseInt(product[key]) !== 0) {
                outputDict[key] = product[key];
            }
        }
    
        let resultDict = [];
    
        const predefinedOrder = [
            'govde', 'emisHunisi', 'talasliImalat', 'rotor',
            'govdeKaynak', 'emisHunisiKaynak', 'talasliImalatKaynak', 'rotorKaynak',
            'balans', 'montaj'
        ];
    
        for (let key of predefinedOrder) {
            if (outputDict.hasOwnProperty(key)) {
                resultDict.push({
                    stationNumbers: getIdsByKeyword(key),
                    key: key,
                    hour: parseFloat(outputDict[key]),
                });
            }
        }
    
        return resultDict;
    };

    const getIdsByKeyword = (keyword) => {
        let ids = [];
        const kaynakIds = [7,8,9,10];

        if (keyword.includes('Kaynak')) {
            ids = kaynakIds;
            return ids;
        }

        for (const key in resources) {
            if (resources.hasOwnProperty(key)) {
                const name = resources[key].name;
                // Use regex to find the word in parentheses
                const match = name.match(/\((.*?)\)/);
                if (match && match[1].trim() === keyword) {
                    ids.push(resources[key].id);
                }
            }
        }

        return ids;
    };

    const getLatestDate = (date1, date2) => {
        // Parse the input dates
        const d1 = new Date(date1);
        const d2 = new Date(date2);
      
        // Compare the dates and return the latest one
        return d1 > d2 ? date1 : date2;
    };

    const onOrderSelect = async (value) => {
        if (scheduledDeliveryDate) {
            setScheduledDeliveryDate(null);
        }
        setOrder(value);
        const orderById = await getOrder(value);
        const currentProduct = await getProduct(orderById.productId);
        setOrderObj(orderById);
        setProduct(currentProduct);
        setResultText(
            `\nSeçilen ürün: ${currentProduct.title} \n\nGovde ${currentProduct.govde}, kaynak ${currentProduct.govdeKaynak} saat\nEmis Hunisi ${currentProduct.emisHunisi}, kaynak ${currentProduct.emisHunisiKaynak} saat\nTalasli Imalat ${currentProduct.talasliImalat}, kaynak ${currentProduct.talasliImalatKaynak} saat\nRotor ${currentProduct.rotor}, kaynak ${currentProduct.rotorKaynak} saat\nBalans ${currentProduct.balans} saat\nMontaj ${currentProduct.montaj} saat\n\n`,
        );
    };

    return (
        <>
            <Helmet>
                <meta name='robots' content='noindex' />
            </Helmet>
            <div className={styles.placeholder} />
            {loading ? (
                <Loader />
            ) : (
                <>
                    {displayPopUp ? (
                        <>
                            <div className={styles.popUpContainer} onClick={handleBackdrop} />
                            <EventPopUpModal currentEvent = {currentEvent} handleBackdrop={handleBackdrop} />
                        </>
                    ) : (
                        null
                    )}

                    <>
                        <div className={styles.container}>
                            <h1>Takvim</h1>
                            <div className={styles.inputContainer}>
                                <div className={styles.searchAndButtonContainer}>
                                    <Dropdown
                                        value={order ? order : -1}
                                        label='Sipariş'
                                        options={'getOrders'}
                                        displayKey={['orderNumber']}
                                        onChange={async (e) => {
                                            onOrderSelect(e.target.value);
                                        }}
                                    />
                                    <Button
                                        loading = {loading || deliveryDateLoader}
                                        disabled = {!order}
                                        buttonText = "Takvime Ekle"
                                        onClick={() => createEvents()}
                                    />
                                </div>
                                
                                {scheduledDeliveryDate ? <p className={styles.centerAlign}>En erken sevk tarihi <b>{scheduledDeliveryDate}</b></p>  
                                :
                                deliveryDateLoader ? <p className={styles.centerAlign}>En erken sevk tarihi hesaplaniyor..</p>
                                :
                                deliveryDate ?
                                    <p className={styles.centerAlign}>En erken sevk tarihi <b>{deliveryDate}</b></p>
                                    :
                                    <p>&nbsp;</p>
                                }
                                <TextArea label='Detaylar' value={resultText} disabled={true} />
                            </div>
                        </div>

                        <Scheduler
                            schedulerData={schedulerData}
                            prevClick={prevClick}
                            nextClick={nextClick}
                            onViewChange={onViewChange}
                            onSelectDate={onSelectDate}
                            eventItemClick={eventClicked}
                        />
                    </>
                </>
            )}
        </>
    );
};

export default withDndContext(SchedulerComponent);
