import { useEffect, useState } from 'react';
import styles from './tracker.module.scss';
import Selnikel from '../../../Assets/Images/Logos/selnikel.png';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { trackOrder, trackPublicOrder } from '../../../Services/api.service';
import Loader from '../../../Components/Loader/Loader';

const Tracker = () => {
    const params = useParams();
    const [loggedIn, ] = useState(localStorage.getItem('token'));
    const [noOrder, setNoOrder] = useState(false);
    const [orderExistsNoEvent, setOrderExistsNoEvent] = useState(false);
    const [progressData, setProgressData] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            let data = '';
            if (loggedIn) {
                data = await trackOrder(params.code);
            }
            else {
                data = await trackPublicOrder(params.code);
            }

            if (data.error) {
                setNoOrder(true);
            }
            else {
                setProgressData(data['resultList']);
                if (data['resultList'] && data['resultList'].length < 1) {
                    setOrderExistsNoEvent(true);
                }
                setDeliveryDate(data['deliveryDate']);
            }

            setLoading(false);
        })();
    }, [params.code, loggedIn]);

    return (
        <>
            <Helmet>
                <title>Selnikel - Tracker</title>
                <meta name='description' content='Tracker' />
                <link rel='canonical' href='/tracker' />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <a href='/'>
                            <img src={Selnikel} alt='Selnikel' />
                        </a>
                        {noOrder ? (
                            <p>Bu sipariş numarasına ait bir siparişimiz bulunmamaktadır gibi görünüyor.</p>
                        ) : (
                            <>
                                {orderExistsNoEvent ? (
                                    <p>
                                        Sipariş numarası için programımızda hiçbir şey bulunmamaktadır{' '}
                                        <u>{params.code}</u>
                                    </p>
                                ) : (
                                    <p>
                                        Siparis numaraniz <u>{params.code}</u>
                                        <br />
                                        {deliveryDate ? (
                                            <>
                                                Tahmini sevk tarihiniz <u>{deliveryDate}</u>
                                            </>
                                        ) : null}
                                    </p>
                                )}
                            </>
                        )}

                        <div className={styles.card}>
                            <div className={styles.tracker}>
                                {progressData.map((stepData, index) => (
                                    <div key={index} className={styles.step}>
                                        <div
                                            className={styles.circle}
                                            style={{
                                                background: `conic-gradient(#4CAF50 ${stepData.completion}%, #bbb ${stepData.completion}%)`,
                                            }}
                                        >
                                            {stepData.completion === 100 ? '✓' : index + 1}
                                        </div>
                                        <div className={styles.labelWrapper}>
                                            <p className={styles.label}>
                                                {stepData.step.includes("Kaynak") ? (
                                                    <>
                                                    {stepData.step.split("Kaynak")[0]}
                                                    <br />
                                                    Kaynak
                                                    </>
                                                ) : (
                                                    stepData.step
                                                )}
                                            </p>
                                            <p className={styles.label}>{`${stepData.completion}%`}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p className={styles.footerTag}>
                            Copyright © {new Date().getFullYear()} Selnikel. All rights reserved.
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Tracker;